import { Undefined } from "runtypes";
import privateApi from ".";
import { APIEndpoints } from "../../utils/constants";
import {
  EntryGenRequestData,
  GenerationQueryParams,
  GetAnswersData,
  GetAnswersMeta,
  GetAnswersQueryParams,
  NewEntryGenRequest,
  PaginationMeta,
} from "../Copilot/EntryGen/generatedTypes";
import { mutationEndpointBuilder, queryEndpointBuilder } from "./utils";

const extendedApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    newEntryGenRequest: mutationEndpointBuilder<
      undefined, // meta-type
      NewEntryGenRequest, // data-type
      FormData // arg type
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.copilot.entryGen.NEW_REQUEST,
      body: (params) => params,
    }),
    getEntryGenRequests: queryEndpointBuilder<
      PaginationMeta, // meta-type
      EntryGenRequestData[], // data-type
      GenerationQueryParams // query-arg type
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: (params) => {
        const { productId, limit, offset } = params;
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append("productId", String(productId));
        urlSearchParams.append("limit", limit.toString());
        urlSearchParams.append("offset", offset.toString());

        return (
          APIEndpoints.copilot.entryGen.GET_GENERATION_REQUESTS +
          `?${urlSearchParams.toString()}`
        );
      },

      extraOptions: {
        checkRunTypes: false,
      },
    }),
    getAnswersForEntryGenRequest: queryEndpointBuilder<
      GetAnswersMeta, // meta-type
      GetAnswersData, // data-type
      GetAnswersQueryParams // query-arg type
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: (params) => {
        const { generationId, limit, offset, includeEmptyAnswers } = params;
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append("generationId", generationId);
        urlSearchParams.append("limit", limit.toString());
        urlSearchParams.append("offset", offset.toString());
        if (includeEmptyAnswers !== null && includeEmptyAnswers !== undefined) {
          urlSearchParams.append(
            "includeEmptyAnswers",
            includeEmptyAnswers.toString(),
          );
        }
        return (
          APIEndpoints.copilot.entryGen.GET_ANSWERS +
          `?${urlSearchParams.toString()}`
        );
      },
      extraOptions: {
        checkRunTypes: false,
      },
    }),
  }),
});

export const {
  useNewEntryGenRequestMutation,
  useGetEntryGenRequestsQuery,
  useGetAnswersForEntryGenRequestQuery,
} = extendedApi;

export const entryGenApi = extendedApi;
