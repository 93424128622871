import { Column } from "@ant-design/plots";
import { Tabs, TabsProps } from "antd";
import { useState } from "react";
import { StatsFilterType } from "..";
import { useGetConciergeAnalyticsQuery } from "../../../features/API/AnalyticsDashboard";
import { ProductId } from "../../../features/API/types";
import styles from "./ConciergeContainer.module.scss";

enum TabKey {
  TimeSaved = "timeSaved",
  CompletionPercentage = "completionPercentage",
  PendingQuestions = "pendingQuestions",
  SearchesOnCoPilot = "searchesOnCoPilot",
  KLUpdate = "klUpdate",
}
interface ConciergeAnalyticsContainerProps {
  productId: ProductId;
  activeFilter: StatsFilterType;
}
const ConciergeAnalyticsContainer = ({
  productId,
  activeFilter,
}: ConciergeAnalyticsContainerProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<TabKey>(TabKey.TimeSaved);
  const { data: conciergeStats } = useGetConciergeAnalyticsQuery({ productId });
  const filterData = () => {
    const data = conciergeStats?.data.conciergeCompletionStats || [];
    switch (activeFilter) {
      case StatsFilterType.LAST_3_MONTHS:
        return data.slice(-3);
      case StatsFilterType.LAST_6_MONTHS:
        return data.slice(-6);
      case StatsFilterType.LAST_YEAR:
        return data;
      default:
        return data;
    }
  };
  const onChange = (key: TabKey) => {
    setSelectedTab(key);
  };
  const items: TabsProps["items"] = [
    {
      key: TabKey.TimeSaved,
      label: "Time Saved",
      children: <div className={styles.TimeSavedContentChart}></div>,
    },
    {
      key: TabKey.CompletionPercentage,
      label: "Completion Percentage",
      children: (
        <div className={styles.CompletionPercentageChart}>
          <Column
            data={filterData()}
            xField="date"
            yField="percentage"
            autoFit={true}
          />
        </div>
      ),
    },
    {
      key: TabKey.PendingQuestions,
      label: "Pending Questions",
      children: <div className={styles.PendingQuestionsChart}></div>,
    },
    {
      key: TabKey.SearchesOnCoPilot,
      label: "Searches on CoPilot",
      children: <div className={styles.SearchesOnCoPilotChart}></div>,
    },
    {
      key: TabKey.KLUpdate,
      label: "KL Update",
      children: <div className={styles.KLUpdateChart}></div>,
    },
  ];

  return (
    <div className={styles.ConciergeAnalyticsContainer}>
      <Tabs
        defaultActiveKey={TabKey.TimeSaved}
        activeKey={selectedTab}
        onChange={(key: string) => onChange(key as TabKey)}
        className={styles.ConciergeAnalyticsTabs}
        items={items}
      />
    </div>
  );
};

export default ConciergeAnalyticsContainer;
