import { Button, Grid, Select } from "antd";
import clsx from "clsx";
import { useState } from "react";
import { InlineProductSelector } from "../../components/ActiveProductSelector";
import AppHeader from "../../components/AppHeader";
import { useGetMeQuery } from "../../features/API/auth";
import { useActiveProduct } from "../../utils/hooks";
import styles from "./AnalyticsContainer.module.scss";
import ConciergeAnalyticsContainer from "./components/ConciergeContainer";

export enum StatsFilterType {
  LAST_3_MONTHS = "Last 3 months",
  LAST_6_MONTHS = "Last 6 months",
  LAST_YEAR = "Last year",
}

const AnalyticsContainer = (): JSX.Element => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const activeProduct = useActiveProduct();

  enum SelectDropDownValue {
    CONCIERGE = "concierge",
  }

  const { data: meData } = useGetMeQuery();
  const isStaff = meData?.user.attributes.isStaff;

  const [selectedValue, setSelectedValue] = useState<SelectDropDownValue>(
    SelectDropDownValue.CONCIERGE,
  );
  const [activeFilter, setActiveFilter] = useState<StatsFilterType>(
    StatsFilterType.LAST_YEAR,
  );

  const handleFilterClick = (filter: StatsFilterType) => {
    setActiveFilter(filter);
  };

  const handleMenuClick = (value: SelectDropDownValue) => {
    setSelectedValue(value);
  };

  const statsFilterOptions = [
    {
      value: StatsFilterType.LAST_3_MONTHS,
      label: StatsFilterType.LAST_3_MONTHS,
    },
    {
      value: StatsFilterType.LAST_6_MONTHS,
      label: StatsFilterType.LAST_6_MONTHS,
    },
    { value: StatsFilterType.LAST_YEAR, label: StatsFilterType.LAST_YEAR },
  ];

  if (!activeProduct) {
    return <></>;
  }

  return (
    <>
      {isStaff && (
        <AppHeader title="Analytics" extra={<InlineProductSelector />} />
      )}
      <div className={styles.AnalyticsContainer}>
        <div className={styles.AnalyticsHeader}>
          <div className={styles.AnalyticsDropdown}>
            <Select
              defaultValue={SelectDropDownValue.CONCIERGE}
              className={styles.AnalyticsDropdownSelect}
              onSelect={handleMenuClick}
              options={[
                { value: SelectDropDownValue.CONCIERGE, label: "Concierge" },
              ]}
            />
          </div>
          {screens.md ? (
            <div className={styles.AnalyticsFilterButtons}>
              {statsFilterOptions.map((filter) => (
                <Button
                  key={filter.value}
                  type="link"
                  className={clsx(styles.FilterButton, {
                    [styles.ActiveFilterButton]: activeFilter === filter.value,
                  })}
                  onClick={() =>
                    handleFilterClick(filter.value as StatsFilterType)
                  }
                >
                  {filter.label}
                </Button>
              ))}
            </div>
          ) : (
            <div className={styles.AnalyticsFilterDropdown}>
              <Select
                value={activeFilter}
                onChange={(value) =>
                  handleFilterClick(value as StatsFilterType)
                }
                options={statsFilterOptions}
                style={{ width: "100%" }}
              />
            </div>
          )}
        </div>
        <div className={styles.AnalyticsBody}>
          {selectedValue === SelectDropDownValue.CONCIERGE && (
            <ConciergeAnalyticsContainer
              productId={activeProduct.id}
              activeFilter={activeFilter}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AnalyticsContainer;
