import privateApi from "..";
import { APIEndpoints } from "../../../utils/constants";
import {
  ConciergeAnalytics,
  GetConciergeAnalyticsParams,
} from "../AnalyticsDashboard/types";
import { queryEndpointBuilder } from "../utils";
const analyticsDashboardApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    getConciergeAnalytics: queryEndpointBuilder<
      undefined,
      ConciergeAnalytics,
      GetConciergeAnalyticsParams
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: ({ productId }) =>
        `${APIEndpoints.analyticsDashboard.GET_CONCIERGE_ANALYTICS}?productId=${productId}`,
      providesTags: [],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
  }),
});
export const { useGetConciergeAnalyticsQuery } = analyticsDashboardApi;
