import { skipToken } from "@reduxjs/toolkit/query";
import { Button, Table } from "antd";
import dayjs from "dayjs";
import startCase from "lodash/startCase";
import { useState } from "react";
import { Link } from "react-router-dom";
import ActiveProductSelector from "../../../components/ActiveProductSelector";
import AppHeader from "../../../components/AppHeader";
import { useGetEntryGenRequestsQuery } from "../../../features/API/entryGen";
import { EntryGenRequestData } from "../../../features/Copilot/EntryGen/generatedTypes";
import { useActiveProduct } from "../../../utils/hooks";
import styles from "./AllEntryGenRequests.module.css";
import NewEntryGenRequestModal from "./NewEntryGenRequestModal";

const GENERATION_REQUESTS_PAGE_SIZE = 20;

const AllGenerationsRequests = () => {
  const [isNewGenerationModalOpen, setIsNewGenerationModalOpen] =
    useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const activeProduct = useActiveProduct();

  const { data: generations, isLoading } = useGetEntryGenRequestsQuery(
    activeProduct
      ? {
          productId: activeProduct.id,
          limit: GENERATION_REQUESTS_PAGE_SIZE,
          offset: GENERATION_REQUESTS_PAGE_SIZE * (pageNumber - 1),
        }
      : skipToken,
  );

  const columns = [
    {
      title: "Document Type",
      key: "documentType",
      dataIndex: ["documentType"],
      render: (text: string, record: EntryGenRequestData) => {
        return <Link to={`answers/${record.id}`}>{text}</Link>;
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: ["status"],
      render: (text: string) => startCase(text),
    },
    {
      title: "Requested",
      key: "createdAt",
      dataIndex: ["createdAt"],
      render: (value: string) => dayjs(value).fromNow(),
    },
    {
      title: "Total Questions",
      key: "total",
      dataIndex: ["totalQuestions"],
    },
    { title: "Processed", key: "processed", dataIndex: ["questionsProcessed"] },
    {
      title: "Entries Generated",
      key: "questions_answered",
      dataIndex: ["questionsAnswered"],
    },
  ];

  return (
    <>
      <div className={styles.HeaderRow}>
        <AppHeader title="Generate Answer Library Entries from Documents" />
        <Button
          className={styles.ButtonUpload}
          type="primary"
          onClick={() => setIsNewGenerationModalOpen(true)}
        >
          Upload Document
        </Button>
      </div>
      <ActiveProductSelector />
      <Table
        columns={columns}
        dataSource={generations?.data}
        loading={isLoading}
        pagination={{
          current: pageNumber,
          pageSize: GENERATION_REQUESTS_PAGE_SIZE,
          total: generations?.meta?.totalCount || 0,
        }}
        onChange={(pagination) => {
          if (pagination.current) {
            setPageNumber(pagination.current);
          }
        }}
        rowKey="id"
      />
      <NewEntryGenRequestModal
        open={isNewGenerationModalOpen}
        onCancel={() => setIsNewGenerationModalOpen(false)}
        onSuccess={() => {
          setIsNewGenerationModalOpen(false);
        }}
      />
    </>
  );
};

export default AllGenerationsRequests;
