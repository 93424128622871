import { skipToken } from "@reduxjs/toolkit/query";
import { Button, Table, message } from "antd";
import { saveAs } from "file-saver";
import startCase from "lodash/startCase";
import { useState } from "react";
import { useParams } from "react-router-dom";
import AppHeader from "../../../components/AppHeader";
import { useGetAnswersForEntryGenRequestQuery } from "../../../features/API/entryGen";
import { Excerpt } from "../../../features/Copilot/EntryGen/generatedTypes";
import { APIEndpoints } from "../../../utils/constants";
import { downloadFile } from "../../../utils/helper";
import styles from "./EntryGenRequestView.module.css";

type EntryGenRequestViewProps = {
  onBack: () => void;
};

const DEFAULT_PAGE_SIZE = 10;

const EntryGenRequestView = (props: EntryGenRequestViewProps) => {
  const { generationId } = useParams();
  const { onBack } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const [isDownloading, setIsDownloading] = useState(false);

  const {
    data: answersResult,
    isLoading,
    isFetching,
  } = useGetAnswersForEntryGenRequestQuery(
    generationId
      ? {
          generationId,
          limit: DEFAULT_PAGE_SIZE,
          offset: DEFAULT_PAGE_SIZE * (pageNumber - 1),
        }
      : skipToken,
  );

  const handleDownload = async () => {
    if (!generationId) {
      return;
    }

    setIsDownloading(true);
    try {
      const blob = await downloadFile(
        `${APIEndpoints.copilot.entryGen.EXPORT_ANSWERS}?generationId=${generationId}`,
      );
      saveAs(blob, `generated_entries_${generationId}.csv`);
    } catch {
      message.error("Failed to download CSV");
    } finally {
      setIsDownloading(false);
    }
  };

  const columns = [
    { title: "Question", key: "question", dataIndex: ["question"] },
    { title: "Answer", key: "answer", dataIndex: ["answer"] },
    {
      title: "Sources",
      key: "sources",
      dataIndex: ["sources"],
      render: (sources: Excerpt[]) => {
        return sources.map((source) => {
          return (
            <div key={source.text}>
              {source.text} (Page: {source.page})
            </div>
          );
        });
      },
    },
  ];
  const status = answersResult?.data?.status;

  return (
    <>
      <AppHeader
        title={
          <div className={styles.HeaderRow}>
            <Button onClick={onBack}> ← </Button>
            Library Entry Generation Request
          </div>
        }
        extra={
          <Button
            onClick={handleDownload}
            loading={isDownloading}
            disabled={answersResult?.data?.status !== "completed"}
          >
            Download CSV
          </Button>
        }
      />

      {status && status !== "completed" && (
        <div>
          <b>Status:</b> {startCase(status || "")}
        </div>
      )}
      <Table
        columns={columns}
        dataSource={answersResult?.data?.answers || []}
        loading={isLoading || isFetching}
        pagination={{
          current: pageNumber,
          total: answersResult?.meta?.totalCount || 0,
          pageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: false,
          showTotal: (total) => `Total ${total} items`,
        }}
        onChange={(pagination) => {
          if (pagination.current) {
            setPageNumber(pagination.current);
          }
        }}
      />
    </>
  );
};

export default EntryGenRequestView;
